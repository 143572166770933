import './contractexample.scss';
import React, { useState } from 'react';
import { Form, Input, Button, Table, Space, Modal, Row, Col, Divider, message } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import AddExample from './addExample';

const ContractExample = () => {
    const [form] = Form.useForm();
    const [columns, setColumns] = useState([
        {title:"標題",dataIndex:"title",key:"title"},
        {title:"操作",key:"action",render: (text,record) => (
            <Space size="small">
                <Button type="default" style={{background:"#4b7cc5",color:"#fff"}} onClick={() => {showEditModal(record)}}>修改</Button>
                <Button type="danger" onClick={() => {showDeleteConfirm(record.id)}}>刪除</Button>
            </Space>
        )},
    ])
    const [data, setData] = useState([]);

    const formSubmit = () =>{
        form.submit();
    };
    const onFormFinish = (values) => {
        fetch(process.env.REACT_APP_URL+"/api/getContractExampleList?title="+values.title, {
            method: "GET",
            headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("_token"),
            }),
        })
        .then(res => res.json())
        .then(data => {
            if( data.error_code == "OK" ) {
                var rdataArr = data.contractExampleData;
                for(var key in rdataArr) {
                    rdataArr[key]["key"] = key;
                }
                setData([...rdataArr]);
            } else if( data.error_code == "AUTH_TOKEN_ERROR" ) {
                localStorage.removeItem("user");
                localStorage.removeItem("_token");
                window.location.reload();
            }
        })
        .catch(e => {
            message.error("發生錯誤, ErrorCode:"+e);
        });
    };
    const onFormFinishFailed = () => {

    };

// ------------------------------------------------------------------------------------------------------------------------------------------------------
    //新增 修改
    const [addModalStatus, setAddModalStatus] = useState(false);
    const [editData, setEditData] = useState({});
    const [editurl, setEditurl] = useState("");
    const showAddModal = () => {
        setEditData({});
        setEditurl("contractExample/add");
        setAddModalStatus(true);
    };
    const showEditModal = (d) => {
        setEditData({...d});
        setEditurl("contractExample/edit");
        setAddModalStatus(true);
    }
// ------------------------------------------------------------------------------------------------------------------------------------------------------
    //刪除
    function showDeleteConfirm(id) {
        Modal.confirm({
            title: '你確定要刪除此筆資料嗎?',
            icon: <ExclamationCircleOutlined />,
            okText: '是',
            okType: 'dashed',
            cancelText: '否',
            onOk() {
                fetch(process.env.REACT_APP_URL+"/api/contractExample/delete", {
                    method: "POST",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("_token"),
                    }),
                    body: JSON.stringify({id: id}),
                })
                .then(res => res.json())
                .then(rdata => {
                    if( rdata.error_code == "OK" ) {
                        formSubmit();
                        message.success("刪除成功");
                    } else if( rdata.error_code == "AUTH_TOKEN_ERROR" ) {
                        localStorage.removeItem("user");
                        localStorage.removeItem("_token");
                        window.location.reload();
                    } else {
                        message.error("刪除失敗, ErrorCode:"+rdata.error_code);
                    }
                })
                .catch(e => {
                    message.error("刪除失敗, ErrorCode:"+e);
                });
            },
        });
    }

    return (
        <div className="contractexample">
            <Form name="contractexample" form={form} onFinish={onFormFinish} onFinishFailed={onFormFinishFailed} layout="horizontal" initialValues={{
                title: "",
            }} >
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="標題" name="title">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                搜尋
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Button type="primary" icon={<PlusOutlined />} style={{float:"right"}} onClick={showAddModal} >新增模板</Button>
            <Divider />

            <Table columns={columns} dataSource={data} />

            <AddExample addModalStatus={addModalStatus} setAddModalStatus={setAddModalStatus} editData={editData} editurl={editurl} formSubmit={formSubmit} />
        </div>
    )    
}

export default ContractExample;