import './login.scss';
import React from 'react';
import { Form, Input, Button, message } from 'antd';

const Login = (props) => {
    const onFinish = (values) => {
        fetch( process.env.REACT_APP_URL+"/api/auth/login", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values)
        } )
        .then( res => res.json() )
        .then( data => {
            if( data.error_code == "OK" ) {
                localStorage.setItem("user", JSON.stringify(data.user));
                localStorage.setItem("_token", data._token);
                props.setIsLogin(true);
            } else {
                localStorage.removeItem("user");
                localStorage.removeItem("_token");
                props.setIsLogin(false);
            }
        } )
        .catch(e => {
    
        });
    };

    const onFinishFailed = (error) => {
        console.log(error);
    };
    return (
        <div className="login">
                <Form
                    name="login"
                    labelCol={{ span: 24 }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item name="username" rules={[
                        { require: true, message: "帳號" }
                    ]}>
                        <Input placeholder="帳號" />
                    </Form.Item>
                    <Form.Item name="password" rules={[
                        { require: true, message: "密碼" }
                    ]}>
                        <Input.Password placeholder="密碼" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            登入
                        </Button>
                    </Form.Item>
                </Form>
        </div>
    )    
}

export default Login;