import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message } from 'antd';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const AddExample = (props) => {
    const [form] = Form.useForm();
    const [addModalStatus, setAddModalStatus] = useState(props.addModalStatus);
    const [addModalLoading, setAddModalLoading] = useState(false);
    const [editurl, setEditurl] = useState("");
    const [saveData, setSaveData] = useState({});
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [editorHTML, setEditorHTML] = useState("");

    useEffect(() => {
        setAddModalStatus(props.addModalStatus);
    },[props.addModalStatus]);
    useEffect(() => {
        setEditurl(props.editurl);
    },[props.editurl]);
    useEffect(() => {
        const edata = {...props.editData};
        setSaveData(edata);
        form.setFieldsValue({
            title: edata.title !== undefined ?  edata.title : "",
        });
        //編輯器
        if(edata.hasOwnProperty("content")){
            const blocksFromHTML = htmlToDraft(edata.content);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML);
            const eState = EditorState.createWithContent(contentState);
            setEditorState(eState);
            var html = draftToHtml( convertToRaw( eState.getCurrentContent() ) );
            setEditorHTML(html);
        }
    },[props.editData]);

    const onEditorStateChange = (editorData) => {
        setEditorState(editorData);
        var html = draftToHtml( convertToRaw( editorData.getCurrentContent() ) );
        setEditorHTML(html);
    };

    const addModalOK = () => {
        var values = form.getFieldValue();
        if(saveData.hasOwnProperty('id')) values.id = saveData.id;
        values.content = editorHTML;
        var txt = (editurl == 'contractExample/add') ? "新增":"修改";
        setAddModalLoading(true);
        fetch(process.env.REACT_APP_URL+"/api/"+editurl, {
            method: "POST",
            headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("_token"),
            }),
            body: JSON.stringify(values),
        })
        .then(res => res.json())
        .then(data => {
            if( data.error_code == "OK" ) {
                props.formSubmit();
                message.success(txt+"成功");
                props.setAddModalStatus(false);
                setAddModalLoading(false);
                form.resetFields();
            } else if( data.error_code == "AUTH_TOKEN_ERROR" ) {
                localStorage.removeItem("user");
                localStorage.removeItem("_token");
                window.location.reload();
            } else {
                message.error(txt+"失敗, ErrorCode:"+data.error_code);
                setAddModalLoading(false);
                form.resetFields();
            }
        })
        .catch(e => {
            message.error(txt+"失敗, ErrorCode:"+e);
            setAddModalLoading(false);
            form.resetFields();
        });
    };

    const addModalCancel = () => {
        form.resetFields();
        props.setAddModalStatus(false);
    };

    return (
        <Modal title="新增模板" visible={addModalStatus} onOk={addModalOK} confirmLoading={addModalLoading} onCancel={addModalCancel} width="80%" okText="送出" cancelText="取消">
            <Form name="addModalForm" form={form} layout="vertical" initialValues={{
                title: ""
            }} >
                <Form.Item label="標題" name="title">
                    <Input />
                </Form.Item>
                <Form.Item label="內容" name="content">
                    <div className="editorDiv" style={{border:"1px solid #ccc",padding: "5px"}}>
                        <Editor editorState={editorState} onEditorStateChange={onEditorStateChange} />
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    )
};

export default AddExample;