import React, { useEffect, useState } from 'react';
import { Modal, Form, Collapse, Row, Col, Input, message, Select, Radio } from 'antd';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const { Panel } = Collapse;
const { Option } = Select;

const AddContract  = (props) => {
    const [form] = Form.useForm();
    const [addModalStatus, setAddModalStatus] = useState(props.addModalStatus);
    const [addModalLoading, setAddModalLoading] = useState(false);
    const [editurl, setEditurl] = useState("");
    const [saveData, setSaveData] = useState({});
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [editorHTML, setEditorHTML] = useState("");
    const [modelSelectStatus, setModelSelectStatus] = useState(false);
    const [modelSelect, setModelSelect] = useState("");
    const [exampleData, setExampleData] = useState(props.exampleData);
    
    useEffect(() => {
        var edata = [...props.exampleData];
        setExampleData(edata);
    },[props.exampleData]);

    useEffect(() => {
        const edata = {...props.editData};
        setSaveData(edata);
        form.setFieldsValue({
            name_a: edata.name_a !== undefined ?  edata.name_a : "",
            id_no_a: edata.id_no_a !== undefined ?  edata.id_no_a : "",
            phone_num: edata.phone_num !== undefined ?  edata.phone_num : "",
            address: edata.address !== undefined ?  edata.address : "",
            name_b: edata.name_b !== undefined ?  edata.name_b : "咏宸國際有限公司",
            principal_b: edata.principal_b !== undefined ?  edata.principal_b : "陳彥辰",
            title: edata.title !== undefined ? edata.title : "",
            enable: edata.enable !== undefined ? edata.enable : 0,
        })

        //編輯器
        var content = ""
        if(edata.hasOwnProperty("content")){
            content = edata.content;
        }
        const blocksFromHTML = htmlToDraft(content);
        const contentState = ContentState.createFromBlockArray(blocksFromHTML);
        const eState = EditorState.createWithContent(contentState);
        setEditorState(eState);
        var html = draftToHtml( convertToRaw( eState.getCurrentContent() ) );
        setEditorHTML(html);

        setModelSelect("");
    },[props.editData]);
    
    useEffect(() => {
        setEditurl(props.editurl);
        if(props.editurl == "contract/add") {
            setModelSelectStatus(true);
        } else {
            setModelSelectStatus(false);
        }
    },[props.editurl]);

    const onExampleSelectChange = (index) => {
        form.setFieldsValue({title: exampleData[index].title});
        setModelSelect(exampleData[index].title);
        const value = exampleData[index].content;
        const blocksFromHTML = htmlToDraft(value);
        const contentState = ContentState.createFromBlockArray(blocksFromHTML);
        const eState = EditorState.createWithContent(contentState);
        setEditorState(eState);
        var html = draftToHtml( convertToRaw( eState.getCurrentContent() ) );
        setEditorHTML(html);
    };

    const onEditorStateChange = (editorData) => {
        setEditorState(editorData);
        var html = draftToHtml( convertToRaw( editorData.getCurrentContent() ) );
        setEditorHTML(html);
    };

    const addModalOK = () => {
        var values = form.getFieldValue();
        if(saveData.hasOwnProperty('id')) values.id = saveData.id;
        values.content = editorHTML;
        var txt = (editurl == 'contract/add') ? "新增":"修改";
        setAddModalLoading(true);
        fetch(process.env.REACT_APP_URL+"/api/"+editurl, {
            method: "POST",
            headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("_token"),
            }),
            body: JSON.stringify(values),
        })
        .then(res => res.json())
        .then(data => {
            if( data.error_code == "OK" ) {
                props.formSubmit();
                message.success(txt+"成功");
                props.setAddModalStatus(false);
                setAddModalLoading(false);
                form.resetFields();
            } else if( data.error_code == "AUTH_TOKEN_ERROR" ) {
                localStorage.removeItem("user");
                localStorage.removeItem("_token");
                window.location.reload();
            } else {
                message.error(txt+"失敗, ErrorCode:"+data.error_code);
                setAddModalLoading(false);
                form.resetFields();
            }
        })
        .catch(e => {
            message.error(txt+"失敗, ErrorCode:"+e);
            setAddModalLoading(false);
            form.resetFields();
        });
    };
    const addModalCancel = () => {
        form.resetFields();
        props.setAddModalStatus(false);
    };

    useEffect(() => {
        setAddModalStatus(props.addModalStatus);
    },[props.addModalStatus]);
    
    return (
        <Modal title="新增合約" visible={addModalStatus} onOk={addModalOK} confirmLoading={addModalLoading} onCancel={addModalCancel} width="80%" okText="送出" cancelText="取消">
            <Form name="addModalForm" form={form} layout="vertical" initialValues={{
                name_a:"", id_no_a:"", phone_num:"", address:"", name_b:"咏宸國際有限公司", principal_b:"陳彥辰", title:"",
            }} >
            <Collapse defaultActiveKey={['1','2','3']} expandIconPosition="right">
                <Panel header="甲方資料" key="1">
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="名稱" name="name_a" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="統一編號" name="id_no_a" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="手機號碼" name="phone_num" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="地址" name="address" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Panel>
                <Panel header="乙方資料" key="2">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="名稱" name="name_b" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="負責人" name="principal_b" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Panel>
                <Panel header="合約內容" key="3">
                    {modelSelectStatus && 
                        <div>
                            <span>模板選擇</span>
                            <Select style={{ width: 120 }} onChange={onExampleSelectChange} value={modelSelect}>
                                {
                                    exampleData.map((obj,index) => {
                                        return (
                                            <Option key={index} value={index}>{obj.title}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    }
                    <Form.Item label="名稱" name="title">
                        <Input />
                    </Form.Item>
                    <Form.Item label="是否啟用" name="enable">
                        <Radio.Group value={0}>
                            <Radio value={0}>不啟用</Radio>
                            <Radio value={1}>啟用</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <div className="editorDiv" style={{border:"1px solid #ccc",padding: "5px"}}>
                        <Form.Item name="content">
                            <Editor editorState={editorState} onEditorStateChange={onEditorStateChange} />
                        </Form.Item>
                    </div>
                </Panel>
            </Collapse>
            </Form>
        </Modal>
    )
};

export default AddContract;