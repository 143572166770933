import './contractview.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, message } from 'antd';
import useWinSize from '@/pages/layouts/useWinSize/useWinSize';
import SignaturePad from "react-signature-canvas";
import signImg from '@/assets/sign_rule.jpg';
import companyImg from '@/assets/company.jpg';

const ContractView = () => {
    const location = useLocation();
    const { search } = location;
    // const date = new Date();
    const [date, setDate] = useState(new Date());
    const [data, setData] = useState({});

    useEffect(() => {
        fetch(process.env.REACT_APP_URL+"/api/getContract"+search, {
            method: "GET",
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
        })
        .then(res => res.json())
        .then(data => {
            if( data.error_code == "OK" ) {
                const rdata = data.data;
                setData({...rdata});
                if( rdata.status ) {
                    var nowDate = new Date(rdata.signature_time);
                    setDate(nowDate);
                }
            } else {
                alert("發生錯誤");
                window.close();
            }
        })
        .catch(e => {
            alert("發生錯誤");
            window.close();
        });

        
        setCWidth(signAreaRef.current.clientWidth);
        setCHeight(signAreaRef.current.clientWidth / 2);
    },[]);

    //簽名
    const [imageURL, setImageURL] = useState(null);
    const sigCanvas = useRef({});
    const signAreaRef = useRef();
    const winWidth = useWinSize();
    const [cwidth, setCWidth] = useState(0);
    const [cheight, setCHeight] = useState(0);

    useEffect(() => {
        setCWidth(signAreaRef.current.clientWidth);
        setCHeight(signAreaRef.current.clientWidth / 2);
    },[winWidth]);

    const clearSign = () => {
        sigCanvas.current.clear();
    };
    const saveSign = () => {
        setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    };
    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    };
    useEffect(() => {
        if(imageURL) {
            const id = search.split('=')[1];
            const formData = new FormData();
            const fileImg = dataURLtoFile(imageURL,`sign_${id}.png`);
            formData.append('file', fileImg, fileImg.name);

            fetch(process.env.REACT_APP_URL+"/api/uploadSign", {
                method: "POST",
                body: formData,
            })
            .then(res => res.json())
            .then(data => {
                if( data.error_code == "OK" ) {
                    window.location.reload();
                } else {
                    message.error("發生錯誤, ErrorCode:"+data.error_code);
                }
            })
            .catch(e => {
                message.error("發生錯誤, ErrorCode:"+e);
            });
        }
    },[imageURL]);
    
    return (
        <div className="contractview">
            <div className="title">{data.title}</div>
            <div className="statement">
                <div>
                    委任人<br />
                    {data.name_a}&nbsp;&nbsp;（以下簡稱甲方）<br />
                    立契約書人<br />
                    受任人<br />
                    <u>&nbsp;&nbsp;&nbsp;&nbsp;{data.name_b}&nbsp;&nbsp;&nbsp;&nbsp;</u>（以下簡稱乙方）
                </div>
            </div>
            <div className="content">
                <div dangerouslySetInnerHTML={{ __html: data.content }} />
                <img src={signImg} className="signimg" />
                <div className="info">
                    <b>
                    <p>
                        甲方：{data.name_a}<br />
                        身分證字號：{data.id_no_a}<br />
                        手機電話：{data.phone_num}<br />
                        地址：{data.address}<br />
                    </p>
                    <p style={{position:"relative", height: "70px"}}>
                        <div style={{float:"left"}}>
                            乙方：{data.name_b}<br />
                            簽約代表：{data.principal_b}<br />
                        </div>
                        {/* <img src={companyImg} className="companyimg" /> */}
                    </p>
                    </b>
                </div>
                <br/><br/>
                <div className="date"><b>中華民國{parseInt(date.getFullYear()) - 1911}年{parseInt(date.getMonth()) + 1}月{date.getDate()}日</b></div>
                    <div className="signArea" ref={signAreaRef}>
                    {
                        data.status ? 
                        <img className="signtureImage" src={process.env.REACT_APP_URL+"/static/sign/"+data.signature_pic_id} />
                        :
                        <div>
                            <SignaturePad ref={sigCanvas} canvasProps={{className: "signatureCanvas", width: cwidth, height: cheight}} />
                            <br/>
                            <Button type="primary" style={{float:"left"}} onClick={clearSign}>重新</Button>
                            <Button type="primary" style={{float:"right"}} onClick={saveSign}>送出簽名</Button>
                            <br/><br/><br/>
                        </div>
                    }
                    </div>
            </div>
        </div>
    )
};

export default ContractView;