import { useState, useEffect } from 'react';

const UseWinSize = () => {
    const [winWidth, setWinWidth] = useState(document.documentElement.clientWidth);

    const handleWinSize = () => {
        setWinWidth(document.documentElement.clientWidth);
    }

    useEffect( () => {
        window.addEventListener('resize', handleWinSize);
        // handleWinSize();
        return (() => {
            window.removeEventListener('resize', handleWinSize);
        });
    }, []);

    return winWidth;
}

export default UseWinSize;