import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, message } from 'antd';

const { Option } = Select;

const AddAccount = (props) => {
    const [form] = Form.useForm();
    const [addModalStatus, setAddModalStatus] = useState(props.addModalStatus);
    const [addModalLoading, setAddModalLoading] = useState(false);
    const [editurl, setEditurl] = useState("");
    const [saveData, setSaveData] = useState({});
    const [disabled, setDisabled] = useState(false);
    
    useEffect(() => {
        const edata = {...props.editData};
        setSaveData(edata);
        form.setFieldsValue({
            username: edata.username !== undefined ?  edata.username : "",
            nickname: edata.nickname !== undefined ?  edata.nickname : "",
            level: edata.level !== undefined ?  edata.level.toString() : "",
            note: edata.note !== undefined ?  edata.note : "",
        })
    },[props.editData]);
    
    useEffect(() => {
        setEditurl(props.editurl);
        if(props.editurl != "register") {
            setDisabled(true);
        }else{
            setDisabled(false);
        }
    },[props.editurl]);

    const addModalOK = () => {
        const values = form.getFieldValue();
        if(saveData.hasOwnProperty('id')) values.id = saveData.id;
        var txt = (editurl == 'register') ? "新增":"修改";
        setAddModalLoading(true);
        fetch(process.env.REACT_APP_URL+"/api/"+editurl, {
            method: "POST",
            headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("_token"),
            }),
            body: JSON.stringify(values),
        })
        .then(res => res.json())
        .then(data => {
            if( data.error_code == "OK" ) {
                props.formSubmit();
                message.success(txt+"成功");
                props.setAddModalStatus(false);
                setAddModalLoading(false);
                form.resetFields();
            } else if( data.error_code == "AUTH_TOKEN_ERROR" ) {
                localStorage.removeItem("user");
                localStorage.removeItem("_token");
                window.location.reload();
            } else {
                message.error(txt+"失敗, ErrorCode:"+data.error_code);
                setAddModalLoading(false);
                form.resetFields();
            }
        })
        .catch(e => {
            message.error(txt+"失敗, ErrorCode:"+e);
            setAddModalLoading(false);
            form.resetFields();
        });
    };
    const addModalCancel = () => {
        props.setAddModalStatus(false);
    };

    useEffect(() => {
        setAddModalStatus(props.addModalStatus);
    },[props.addModalStatus]);

    return(
        <Modal title="新增帳號" visible={addModalStatus} onOk={addModalOK} confirmLoading={addModalLoading} onCancel={addModalCancel} okText="送出" cancelText="取消">
            <Form name="addAccountForm" form={form} initialValues={{
                username: "", nickname: "", password: "", passwordCheck: "", level: "", note: "",
            }} >
                <Form.Item label="帳號" name="username">
                    <Input disabled={disabled} />
                </Form.Item>
                <Form.Item label="密碼" name="password">
                    <Input.Password />
                </Form.Item>
                <Form.Item label="密碼確認" name="passwordCheck">
                    <Input.Password />
                </Form.Item>
                <Form.Item label="暱稱" name="nickname">
                    <Input />
                </Form.Item>
                <Form.Item label="權限" name="level">
                    {/* <Input /> */}
                    <Select disabled={disabled}>
                        <Option value="1">管理員</Option>
                        <Option value="2">編輯</Option>
                        <Option value="3">版主</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="備註" name="note">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )

};

export default AddAccount;