import './accountlist.scss';
import React, { useState } from 'react';
import { Form, Input, Row, Col, Button, Divider, Table, Space, Modal, message } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import AddAccount from './addAccount';

const AccountList = () => {
    const [form] = Form.useForm();
    const levelArr = ["系統","管理員","編輯","版主"];
    //搜尋
    const [columns, setColumns] = useState([
        {title:"帳號", dataIndex:"username", key:"username"},
        {title:"暱稱", dataIndex:"nickname", key:"nickname"},
        {title:"會員等級", dataIndex:"level", key:"level", render: (text,record) => (levelArr[text])},
        {title:"最後登入IP", dataIndex:"now_login_ip", key:"nowLoginIp"},
        {title:"最後登入時間", dataIndex:"last_login_time", key:"lastLoginTime"},
        {title:"操作", key:"action",render: (text,record) => (
            <Space size="small">
                <Button type="default" onClick={() => {showEditModal(record)}}>修改</Button>
                <Button type="danger" onClick={() => {showDeleteConfirm(record.id)}}>刪除</Button>
            </Space>
        )},
    ]);
    const [data,setData] = useState([
        // {key:0, username: "aa", nickname: "我是aa", level: "1", nowLoginIp: "127.0.0.1", lastLoginTime: "2022-01-01 11:11:11"},
        // {key:1, username: "bb", nickname: "我是bb", level: "1", nowLoginIp: "127.0.0.1", lastLoginTime: "2022-01-01 11:11:11"}
    ]);

    const formSubmit = () =>{
        form.submit();
    };
    const onFormFinish = (values) => {
        var searchStr = "?username="+values.username+"&nickname="+values.nickname+"&nowLoginIp="+values.nowLoginIp;
        fetch(process.env.REACT_APP_URL+"/api/getUserList"+searchStr, {
            method: "GET",
            headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("_token"),
            }),
        })
        .then(res => res.json())
        .then(data => {
            if( data.error_code == "OK" ) {
                var rdataArr = data.data;
                for(var key in rdataArr) {
                    rdataArr[key]["key"] = key;
                }
                setData([...rdataArr]);
            } else if( data.error_code == "AUTH_TOKEN_ERROR" ) {
                localStorage.removeItem("user");
                localStorage.removeItem("_token");
                window.location.reload();
            }
        })
        .catch(e => {
            message.error("發生錯誤, ErrorCode:"+e);
        });
    };
    const onFormFinishFailed = () => {

    };
    
    //新增修改
    const [addModalStatus, setAddModalStatus] = useState(false);
    const [editData, setEditData] = useState({});
    const [editurl, setEditurl] = useState("");
    const showAddModal = () => {
        setEditData({});
        setEditurl("register");
        setAddModalStatus(true);
    };
    const showEditModal = (d) => {
        setEditData({...d});
        setEditurl("user/edit");
        setAddModalStatus(true);
    };

    //刪除
    const showDeleteConfirm = (id) => {
        Modal.confirm({
            title: '你確定要刪除此筆資料嗎?',
            icon: <ExclamationCircleOutlined />,
            okText: '是',
            okType: 'dashed',
            cancelText: '否',
            onOk() {
                fetch(process.env.REACT_APP_URL+"/api/user/delete", {
                    method: "POST",
                    headers: new Headers({
                      'Content-Type': 'application/json',
                      'Authorization': "Bearer " + localStorage.getItem("_token"),
                    }),
                    body: JSON.stringify({id: id}),
                })
                .then(res => res.json())
                .then(rdata => {
                    if( rdata.error_code == "OK" ) {
                        formSubmit();
                        message.success("刪除成功");
                    } else if( rdata.error_code == "AUTH_TOKEN_ERROR" ) {
                        localStorage.removeItem("user");
                        localStorage.removeItem("_token");
                        window.location.reload();
                    } else {
                        message.error("刪除失敗, ErrorCode:"+rdata.error_code);
                    }
                })
                .catch(e => {
                    message.error("刪除失敗, ErrorCode:"+e);
                });
            },
        });
    }

    return (
        <div className="accountlist">
            <Form name="accountlist" form={form} onFinish={onFormFinish} onFinishFailed={onFormFinishFailed} initialValues={{
                username: "", nickname: "", nowLoginIp: "",
            }} >
                <Row>
                    <Col span={7}>
                        <Form.Item label="帳號" name="username">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item label="暱稱" name="nickname">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item label="IP" name="nowLoginIp">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                搜尋
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Divider />
            <Button type="primary" icon={<PlusOutlined />} style={{float:"right"}} onClick={showAddModal} >新增帳號</Button>
            <br />
            <br />

            <AddAccount addModalStatus={addModalStatus} setAddModalStatus={setAddModalStatus} editData={editData} editurl={editurl} formSubmit={formSubmit} />

            <Table columns={columns} dataSource={data} />
        </div>
    )
};

export default AccountList;