import './contractlist.scss';
import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, Table, Space, Modal, Row, Col, Divider, message } from 'antd';
import { CheckOutlined, CloseOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import AddContract from './addContract';

const ContractList = () => {
    const [form] = Form.useForm();
    const [searchType, setsearchType] = useState("");
    const [columns, setColumns] = useState([
        {title:"啟用",dataIndex:"enable",key:"enable",render: enable => {return (enable)? <CheckOutlined style={{color:"#52c41a"}} />:<CloseOutlined style={{color:"#eb2f96"}} />}},
        {title:"狀態",dataIndex:"status",key:"status",render: status => {return (status)? <span>已簽約</span>:<span>未簽約</span>}},
        {title:"甲方名稱",dataIndex:"name_a",key:"name_a"},
        {title:"甲方身分證字號",dataIndex:"id_no_a",key:"id_no_a"},
        {title:"乙方名稱",dataIndex:"name_b",key:"name_b"},
        {title:"乙方負責人",dataIndex:"principal_b",key:"principal_b"},
        {title:"建立時間",dataIndex:"created_at",key:"created_at"},
        {title:"最後修改時間",dataIndex:"updated_at",key:"updated_at"},
        {title:"簽名時間",dataIndex:"signature_time",key:"signature_time"},
        {title:"操作",key:"action",render: (text,record) => (
            <Space size="small">
                <Button type="default" style={{background:"#3ac4e3",color:"#fff"}} onClick={() => {showContract(record.id)}}>預覽合約</Button>
                {
                    (!record.status)? <Button type="default" style={{background:"#4b7cc5",color:"#fff"}} onClick={() => {showEditModal(record)}}>修改</Button> : ""
                }
                <Button type="danger" onClick={() => {showDeleteConfirm(record.id)}}>刪除</Button>
            </Space>
        )},
        

    ])
    const [data, setData] = useState([
        // {key:0,enable:1,status:1,name_a:"aaa",id_no_a:"A123456789",name_b:"bbb",principal_b:"yoyoyo",created_at:"2022-01-01",updated_at:"2022-01-01",signature_time:"2022-01-01"},
        // {key:1,enable:0,status:0,name_a:"aaa",id_no_a:"A123456789",name_b:"bbb",principal_b:"yoyoyo",created_at:"2022-01-01",updated_at:"2022-01-01",signature_time:"2022-01-01"}
    ]);
    const formSubmit = () =>{
        form.submit();
    };
    const onFormFinish = (values) => {
        var searchStr = "?enable="+values.enable+"&status="+values.status+"&sort="+values.sort;
        if( values.model != "" ) {
            searchStr += "&"+values.model+"="+(values[values.model]? values[values.model]:"");
        }

        fetch(process.env.REACT_APP_URL+"/api/getContractList"+searchStr, {
            method: "GET",
            headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("_token"),
            }),
        })
        .then(res => res.json())
        .then(data => {
            if( data.error_code == "OK" ) {
                var rdataArr = data.contractData;
                for(var key in rdataArr) {
                    rdataArr[key]["key"] = key;
                    rdataArr[key]["created_at"] = rdataArr[key]["created_at"].split(" ")[0];
                    rdataArr[key]["updated_at"] = rdataArr[key]["updated_at"].split(" ")[0];
                    rdataArr[key]["signature_time"] = rdataArr[key]["signature_time"].split(" ")[0];
                    rdataArr[key]["signature_time"] = rdataArr[key]["signature_time"] == "0001-01-01" ? "":rdataArr[key]["signature_time"];
                }
                setData([...rdataArr]);
            } else if( data.error_code == "AUTH_TOKEN_ERROR" ) {
                localStorage.removeItem("user");
                localStorage.removeItem("_token");
                window.location.reload();
            }
        })
        .catch(e => {
            message.error("發生錯誤, ErrorCode:"+e);
        });
    };
    const onFormFinishFailed = (errorInfo) => {
        
    };
// ------------------------------------------------------------------------------------------------------------------------------------------------------
    //新增 修改
    const [addModalStatus, setAddModalStatus] = useState(false);
    const [editData, setEditData] = useState({});
    const [editurl, setEditurl] = useState("");
    const showAddModal = () => {
        setEditData({});
        setEditurl("contract/add");
        setAddModalStatus(true);
    };
    const showEditModal = (d) => {
        setEditData({...d});
        setEditurl("contract/edit");
        setAddModalStatus(true);
    }
// ------------------------------------------------------------------------------------------------------------------------------------------------------
    //刪除
    function showDeleteConfirm(id) {
        Modal.confirm({
            title: '你確定要刪除此筆資料嗎?',
            icon: <ExclamationCircleOutlined />,
            okText: '是',
            okType: 'dashed',
            cancelText: '否',
            onOk() {
                fetch(process.env.REACT_APP_URL+"/api/contract/delete", {
                    method: "POST",
                    headers: new Headers({
                      'Content-Type': 'application/json',
                      'Authorization': "Bearer " + localStorage.getItem("_token"),
                    }),
                    body: JSON.stringify({id: id}),
                })
                .then(res => res.json())
                .then(rdata => {
                    if( rdata.error_code == "OK" ) {
                        formSubmit();
                        message.success("刪除成功");
                    } else if( rdata.error_code == "AUTH_TOKEN_ERROR" ) {
                        localStorage.removeItem("user");
                        localStorage.removeItem("_token");
                        window.location.reload();
                    } else {
                        message.error("刪除失敗, ErrorCode:"+rdata.error_code);
                    }
                })
                .catch(e => {
                    message.error("刪除失敗, ErrorCode:"+e);
                });
            },
        });
    }

// ------------------------------------------------------------------------------------------------------------------------------------------------------
    //預覽合約
    const showContract = (id) => {
        window.open(window.location.origin+"/contractview?id="+id);
    };

    const [exampleData, setExampleData] = useState([]);
    
    useEffect(() => {
        fetch(process.env.REACT_APP_URL+"/api/getContractExampleList", {
            method: "GET",
            headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("_token"),
            }),
        })
        .then(res => res.json())
        .then(data => {
            if( data.error_code == "OK" ) {
                var rdataArr = data.contractExampleData;
                setExampleData([...rdataArr]);
            } else if( data.error_code == "AUTH_TOKEN_ERROR" ) {
                localStorage.removeItem("user");
                localStorage.removeItem("_token");
                window.location.reload();
            }
        })
        .catch(e => {
            message.error("發生錯誤, ErrorCode:"+e);
        });
    },[]);

    return (
        <div className="contractlist">
            <Form name="contractlist" form={form} onFinish={onFormFinish} onFinishFailed={onFormFinishFailed} layout="horizontal" initialValues={{
                model: "", enable: "", status: "", sort: "created_at desc",
            }} >
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="項目" name="model" >
                            <Select onChange={(val)=>{setsearchType(val)}}>
                                <Select.Option value="">搜尋項目</Select.Option>
                                <Select.Option value="name_a">甲方名稱</Select.Option>
                                <Select.Option value="id_no_a">甲方身分證字號</Select.Option>
                                <Select.Option value="name_b">乙方名稱</Select.Option>
                                <Select.Option value="principal_b">乙方負責人</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label=":" name={searchType}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="合約狀態" name="enable" >
                            <Select>
                                <Select.Option value="">全部</Select.Option>
                                <Select.Option value="0">停用</Select.Option>
                                <Select.Option value="1">啟用</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="簽約狀態" name="status" >
                            <Select>
                                <Select.Option value="">全部</Select.Option>
                                <Select.Option value="0">未簽約</Select.Option>
                                <Select.Option value="1">已簽約</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="排序方式" name="sort" >
                            <Select>
                                <Select.Option value="created_at desc">建立時間(由新到舊)</Select.Option>
                                <Select.Option value="created_at">建立時間(由舊到新)</Select.Option>
                                <Select.Option value="signatureTime desc">簽名時間(由新到舊)</Select.Option>
                                <Select.Option value="signatureTime">簽名時間(由舊到新)</Select.Option>
                                <Select.Option value="updated_at desc">修改時間(由新到舊)</Select.Option>
                                <Select.Option value="updated_at">修改時間(由舊到新)</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                搜尋
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Divider />
            <Button type="primary" icon={<PlusOutlined />} style={{float:"right"}} onClick={showAddModal} >新增合約</Button>
            <br />
            <br />
            <Table columns={columns} dataSource={data} />

            <AddContract addModalStatus={addModalStatus} setAddModalStatus={setAddModalStatus} editData={editData} editurl={editurl} exampleData={exampleData} formSubmit={formSubmit} />
        </div>
    )    
}

export default ContractList;