import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Layout, Menu, Row, Col } from 'antd';
import {
  TeamOutlined, UserOutlined, ReadOutlined, ProfileOutlined
} from '@ant-design/icons';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const Main = (props) => {
    const [user, setUser] = useState({});
    useEffect(() => {
        const u = props.user;
        setUser({...u});
    },[props.user]);
    return (
        <div className="App">
            <Layout>
            <Sider width="200" breakpoint="lg" collapsedWidth="0" style={{ minHeight: "100vh", userSelect: "none" }}>
                <Header className="site-layout-sub-header-background" 
                style={{ textAlign: "center", color: "#fff", fontWeight: 900, fontSize: "16px", padding: 0, border: "0.5px solid #fff" }} >
                <Link to="/">YonChen 管理系統</Link>
                </Header>
                <Menu theme="dark" mode="inline">
                {user.level < 3 && 
                    <SubMenu key="1" icon={<TeamOutlined />} title="帳號管理">
                        <Menu.Item key="101" icon={<UserOutlined />}>
                            <Link to="/accountlist">
                                帳號列表
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                }
                    <SubMenu key="2" icon={<ReadOutlined />} title="合約管理">
                    {user.level < 3 &&
                        <Menu.Item key="201" icon={<ProfileOutlined />}>
                            <Link to="/contractexample">
                                合約模板
                            </Link>
                        </Menu.Item>
                    }
                        <Menu.Item key="202" icon={<ProfileOutlined />}>
                            <Link to="/contractlist">
                                合約列表
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </Sider>
            <Layout style={{ minHeight: "100vh" }}>
                <Header className="site-layout-sub-header-background" style={{ padding: 0 }} >
                <Row>
                    <Col span={12}>
                    
                    </Col>
                    <Col span={12} className="userInfo">
                    <span>您好, {user.nickname}</span>
                    <div className="logout" onClick={props.logout}>登出</div>
                    </Col>
                </Row>
                </Header>
                <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background" style={{ padding: 24 }}>
                    <Outlet />
                </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>YonChen ©2022 Created by Willson, Adonis</Footer>
            </Layout>
            </Layout>
        </div>
    )
}

export default Main;