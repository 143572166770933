import './App.scss';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Main from '@/pages/layouts/main';
import Login from '@/pages/login/login';
import Home from '@/pages/home/home';
import AccountList from '@/pages/accounts/accountlist/accountlist';
import ContractList from '@/pages/contract/contractlist/contractlist';
import ContractExample from '@/pages/contract/contractexample/contractexample';
import ContractView from '@/pages/contract/contractview/contractview';



function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    //檢查登入
    if( localStorage.getItem("_token") ) {
      const u = JSON.parse(localStorage.getItem("user"));
      if( u ) {
        setIsLogin(true);
        setUser({...u});
      } else {
        setIsLogin(false);
      }
    } else {
      setIsLogin(false);
    }
  },[isLogin]);

  const logout = () => {
    setIsLogin(false);
    setUser({});
    localStorage.removeItem("user");
    localStorage.removeItem("_token");
  };
  
  return (
    isLogin? 
    <Router>
      <Routes>
        <Route path="/" element={<Main logout={logout} user={user} />}>
          <Route path="/" element={<Home />} />
          <Route path="/accountlist" element={<AccountList />} />
          <Route path="/contractlist" element={<ContractList />} />
          <Route path="/contractexample" element={<ContractExample />} />
          <Route path="*" element={<Home />} />
        </Route>
        <Route path="/contractview" element={<ContractView />} />
      </Routes>
    </Router>
    :
    <Router>
      <Routes>
        <Route path="/" element={<Login isLogin={isLogin} setIsLogin={setIsLogin} />} />
        <Route path="/contractview" element={<ContractView />} />
        <Route path="*" element={<Login isLogin={isLogin} setIsLogin={setIsLogin} />} />
      </Routes>
    </Router>
  );
}

export default App;
